<script setup lang="ts">
import { ref } from 'vue'

import { getClasses } from '~/js/composables/classes'
import { Analytics } from '~/js/interfaces/analytics'

import BadgeCounter from '~/vue/_atoms/badges/BadgeCounter.vue'

interface Props {
    badge?: string | number | null,
    content?: string,
    ga?: Analytics | null,
    icon?: string,
    iconPosition?: 'before' | 'after',
    link?: string | null,
    type?: 'a' | 'button' | 'div',
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    badge: null,
    content: '',
    iconPosition: 'before',
    link: null,
    size: 'default',
    type: 'button',
    utilities: ''
})

const classes = ref({
    _default: 'flex justify-between items-center',
    border: 'border border-yellow-600',
    color: 'text-purple-600',
    focus: 'focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-1',
    font: 'font-primary font-bold',
    active: 'active:bg-yellow-600',
    hover: 'hover:ring-2 hover:ring-yellow-600',
    radius: 'rounded-sm rounded-br-2xl ',
    spacing: 'py-2 px-4 gap-2 ',
    size: 'w-fit',
    utilities: props.utilities
})

</script>

<template>
    <component
        :is="type"
        :class="[
            getClasses(classes), 
            iconPosition == 'after' ? 'flex-row-reverse' : 'flex-row',
            utilities
        ]"
        :href="link"
        :title="type != 'div' ? ga?.label ?? content : false"
        :data-tag-category="type != 'div' ? ga?.category ?? '' : false"
        :data-tag-action="type != 'div' ? ga?.action ?? '' : false"
        :data-tag-label="type != 'div' ? ga?.label ?? '' : false"
    >
        <span class="order-2 whitespace-nowrap flex items-center">
            <span class="pt-1">{{ content }}</span>
        </span>

        <span 
            v-if="icon"
            :class="[
                'pt-1 text-xl',
                content.length > 0 ? (iconPosition == 'after' ? 'ml-1' : 'mr-1') : '',
            ]"
        >
            <i :class="icon"/>
        </span>

        <BadgeCounter
            class="order-2"
            v-if="badge"
            :label="badge"
        />
    </component>
</template>